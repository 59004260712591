import Box from "@mui/material/Box";
import Overline from "src/components/Overline";
import Paragraph from "src/components/Paragraph";
import { Link } from "gatsby-theme-material-ui";
import * as React from "react";
import { Helmet } from "react-helmet";
import SubHeader from "src/components/SubHeader";
import Blockquote from "src/components/Blockquote";
import Container from "src/components/Container";
import ImageHeaderUnit from "src/components/ImageHeaderUnit";
import ImageNavList from "src/components/ImageNavList";
import Layout from "src/components/Layout";
import grid1 from "src/images/what-we-do/img-grid1.jpg";
import grid2 from "src/images/what-we-do/img-grid2.jpg";
import playgroundImg from "src/images/what-we-do/playground.jpg";
import img from "src/images/what-we-do/why-we-do-this-work-bg.jpg";

export default function TheImpactOfHomelessnessPage() {
  return (
    <Layout>
      <Helmet>
        <title>The Impact of Homelessness</title>
      </Helmet>
      <ImageHeaderUnit
        subtitle={
          <>
            <Link to="/what-we-do">What We Do</Link> &raquo; Why We Do This Work
          </>
        }
        title="The Impact of Homelessness"
        img={img}
      />
      <Container>
        <Paragraph>
          With a focus on our community in East Oakland,{" "}
          <b>
            EOCP is the first line of defense for people experiencing
            homelessness.
          </b>{" "}
          EOCP knows what is at stake. We provide comprehensive and dignified
          services for what each person needs right now —{" "}
          <b>
            we create a space of belonging and consistent support while they
            move towards realizing their long-term aspirations and growth.
          </b>
        </Paragraph>
        <Paragraph>
          Our <Link to="/residential-services">residential</Link> and{" "}
          <Link to="/community-based-services">community-based services</Link>{" "}
          support each person’s move into a new chapter of life, even beyond a
          permanent housing placement. EOCP pairs each person with dedicated
          staff who support them with training in life and financial skills,
          connections to mental health and medical counseling, play and academic
          activities for youth, and nourishing meals.
        </Paragraph>
      </Container>
      <Box
        py={3}
        sx={{
          width: { sm: 320 },
          float: { sm: "left" },
          marginRight: { sm: 3 },
        }}
      >
        <Box
          component="img"
          display="block"
          src={grid1}
          alt="People helped by EOCP"
          sx={{ width: "100%" }}
        />
        <Blockquote invert my={0}>
          Since 1990, EOCP has served over 30,000 people experiencing
          homelessness in Alameda County.
          <br />
          <br />
          We meet individuals and families wherever they are in their journey in
          moving past homelessness, and we stick with them throughout that
          journey.
        </Blockquote>
        <Box
          component="img"
          display="block"
          src={grid2}
          alt="People helped by EOCP"
          sx={{ width: "100%" }}
        />
      </Box>

      <Container sx={{ mt: { md: 3 }, pt: { sm: 2 } }}>
        <Paragraph>
          We know that people’s experiences of homelessness are varied and
          complex.{" "}
          <b>
            Our strength is in understanding how poverty and oppression might
            affect an individual’s life in multiple ways, while steadily caring
            for each person’s unique needs.
          </b>
        </Paragraph>
      </Container>

      <Container>
        <Overline mt={3}>What is the impact of homelessness?</Overline>
        <Paragraph>
          At EOCP, we recognize that{" "}
          <b>
            homelessness especially impacts those who have been historically
            neglected by our economic, political, and social ecosystems.
          </b>
        </Paragraph>
        <Paragraph>
          People experience homelessness due to unaffordable housing costs,
          low-wage jobs and job insecurity, disproportionate health impacts due
          to the COVID-19 pandemic, and precarious access to the benefits which
          enable people to thrive and grow.
        </Paragraph>
        <Paragraph>
          Many of the individuals and families served by EOCP{" "}
          <b>need urgent spaces of safety or accommodation</b> due to violence,
          mental or physical disability, lack of support after leaving foster
          care, or when they are discharged from an institution (e.g. hospital,
          jail) with no available resources to obtain housing.
        </Paragraph>
      </Container>
      <Box py={3}>
        <Blockquote>
          Learn more about the experiences and impacts of homelessness at{" "}
          <Link href="https://everyonehome.org/">EveryOne Home</Link>, the
          coordinated initiative to end homelessness in Alameda County.
        </Blockquote>
      </Box>
      <Container>
        <Box
          component="img"
          src={playgroundImg}
          sx={{
            width: {
              xs: "33vw",
            },
            float: "right",
            ml: 2,
            mb: 2,
            maxWidth: 400,
          }}
        />
        <Paragraph>
          <b>Families with children</b> in Oakland are the future of our
          community. And yet, those who experience homelessness face acute and
          limiting impacts on their health and development, educational growth,
          and economic stability.
        </Paragraph>
        <Paragraph>
          <b>Individual single adults</b> experiencing homelessness are often
          more likely to live in parks or on the streets, and for longer or more
          frequent periods. This limits stable access to treat and prevent
          chronic health conditions, and puts them at risk for harmful
          encounters with public institutions such as hospitals, jails, and
          detention centers — as well as community violence and harm.
        </Paragraph>
        <Paragraph>
          We do our work with a <b>sense of belonging and dedication</b> to our
          community, and know that we all thrive when our fellow. community
          members no longer experience harm due to homelessness.
        </Paragraph>
      </Container>
      <Box sx={{ clear: "both" }} />
      <Blockquote
        sx={{ maxWidth: 600, marginLeft: "auto", marginRight: "auto" }}
        mt={3}
        mb={0}
        invert
      >
        At EOCP, our approach is responsive and personalized. We are committed
        to reversing the harmful impacts on our community’s health and
        well-being.
      </Blockquote>

      <Box sx={{ backgroundColor: "green.50" }} py={4} mt={{ xs: 0, sm: 5 }}>
        <Container>
          <SubHeader>Learn more about our approach and our programs</SubHeader>
          <ImageNavList
            links={[
              "/what-we-do",
              "/residential-services",
              "/community-based-services",
            ]}
          />
        </Container>
      </Box>
    </Layout>
  );
}
